<template>
  <div class="flex flex-col items-center justify-center" style="min-height: 100%">
    <div class="flex flex-col items-center justify-center m-1 md:mt-20" style="min-height: 100%">
      <div class="lg:flex-row flex-col pb-10 mt-10">
        <div class="flex justify-center align-middle">
          <img src="../assets/images/LogoWhite.svg" class="classImgLogo">
        </div>
        <div class="flex justify-center align-middle items-center lg:py-3 py-1">
          <div style="margin: auto 2px;">
            <img class="footerImg classImgDesc" src="../assets/images/EyecandyDescGray.svg">
          </div>
        </div>
      </div>
      <div class="text-white flex flex-row text-center pb-12">
        <div class="content flex flex-col items-center justify-between">
          <div>
            <div class="bold md:h1 h5">The most exclusive<br />NFT Art Market is<br />coming soon.</div>
          </div>
        </div>
      </div>
      <vip-list/>
    </div>
    <div style="overflow: hidden;">
      <video autoplay loop muted>
        <source src="../assets/videos/bg.mp4" type="video/mp4">
        Your browser does not support HTML video.
      </video>
    </div>
    <div class="flex flex-col items-center gap-10 pt-28 sm:absolute sm:bottom-0">
      <div class="flex sm:flex-row flex-col items-center sm:gap-20 gap-5 ">
        <a href="https://www.instagram.com/eyecandy_nfts/" target="_blank" class="ctaRegular bold mainTextWhite100">Instagram</a>
        <a href="https://twitter.com/EyeCandy_NFTs" target="_blank" class="ctaRegular bold mainTextWhite100">Twitter</a>
        <a href="https://medium.com/@EyeCandy_NFTs" target="_blank" class="ctaRegular bold mainTextWhite100">Medium</a>
        <a href="mailto:hello@eyecandy.io" target="_blank" class="ctaRegular bold mainTextWhite100">Contact</a>
      </div>
      <a class="captionLarge mainTextWhite100 pb-10 text-center">
        ©2022 EyeCandy, an <a href="https://ethernallabs.com/" target="_blank">Ethernal Labs</a> Company. All Rights Reserved
      </a>
    </div>
  </div>
</template>

<script>


import VipList from "@/components/VipList";
export default {
  name: 'Home',
  components: {VipList},
}
</script>

<style scoped>

video {
  width: 100vw; /* Could also use width: 100%; */
  height: 100vh;
  object-fit: cover;
  position: fixed; /* Change position to absolute if you don't want it to take up the whole page */
  left: 0px;
  top: 0px;
  z-index: -1;
}

.classImgLogo {
  height: 67px;
  width: 134px;
}

.classImgDesc {
  height: 15px;
  width: 65px;
}



@media only screen and (max-width : 350px) {
  .classImgLogo {
    height: 50px;
    width: 100px
  }

  .classImgDesc {
    height: 10px;
    width: 45px;
  }

}
</style>
