<template>
  <div>
    <div class="self-center flex-grow w-full">
      <Home/>
    </div>
  </div>
</template>

<script>
import Home from "@/pages/Home";

export default {
  mounted() {
    document.title = 'EyeCandy - Marketplace';
  },
  components: {
    Home
  }
}
</script>
<style>
</style>