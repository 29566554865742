import {createApp} from 'vue';
import App from './App.vue';
import router from './router';
import store from './store';
import MasonryWall from '@yeger/vue-masonry-wall';
import Notifications from '@kyvg/vue3-notification';
import Moralis from './plugins/moralis';
import {Skeletor} from 'vue-skeletor';
import './index.scss';
import 'vue3-carousel/dist/carousel.css';
import 'vue-skeletor/dist/vue-skeletor.css';
import "tailwindcss/tailwind.css";


createApp(App)
  .provide('$moralis', Moralis)
  .component(Skeletor.name, Skeletor)
  .use(store)
  .use(router)
    .use(MasonryWall)
    .use(Notifications)
    .mount('#app')
