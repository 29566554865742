import {createStore} from 'vuex'
import createPersistedState from "vuex-persistedstate";

export default createStore({
    state: {
        user: null,
    },
    getters:{
    },
    mutations: {
    },
    actions: {
    },
    plugins: [
        createPersistedState({
            storage: window.sessionStorage,
        }),
    ]
})