<template>
  <div class="flex-1">
    <div class="flex flex-col gap-8 text-center items-center">
      <h4 class="bold mainTextWhite100" v-if="!sendSuccess">
        Join our VIP list and be the first to know when we launch.
      </h4>
      <h4 class="bold mainTextWhite100" v-if="sendSuccess">
        You just got access to our VIP list!<br />
        Stay tuned, big news will be coming your way soon!
      </h4>

      <div class="errorVipList bodyTiny" v-if="error">
        {{ error }}
      </div>
      <div class="inputEmailVipList bodyMedium w-full" v-if="!sendSuccess">
        <label>
          <input placeholder="add your email" @keyup="changeEmailValidation()" type="text" v-model="email">
        </label>
      </div>
      <div class="buttonVipListDisable flex flex-row items-center" style="width: 160px" :class="{buttonVipListSuccess: sendSuccess}"
           @click="checkForm">
        <div>
          <div v-if="!sendSuccess" class="ctaRegular bold mainTextBlack100">
            Become a VIP
          </div>
          <div :class="{buttonVipListSuccess: sendSuccess}" class="flex items-center bold" v-if="sendSuccess">
            Welcome&nbsp;in!&nbsp;&nbsp;<img src="../assets/images/VerifiedBlack.svg">
          </div>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
import {saveNewEmail} from "@/services/VipList"

export default {
  name: "VipList",
  data() {
    return {
      error: null,
      email: null,
      isValidEmail: false,
      sendSuccess: false
    }
  },
  methods: {
    checkForm: async function () {
      if (this.sendSuccess) return;

      this.error = null;

      if (!this.isValidEmail) {
        this.error = 'Please add a valid email or check your spelling.';
        return;
      }

      saveNewEmail(this.email).then((result)=> {
        if(result.success){
          this.sendSuccess = true;
          return;
        }

        this.error = result.message;
        return;
      });

    },
    changeEmailValidation() {
      let re = /^(([^<>()[\]\\.,;:\s@"]+(\.[^<>()[\]\\.,;:\s@"]+)*)|(".+"))@((\[[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\])|(([a-zA-Z\-0-9]+\.)+[a-zA-Z]{2,}))$/;

      this.isValidEmail = re.test(this.email);
    }
  },
}
</script>

<style scoped>

input[type="text"] {
  background: rgba(0, 0, 0, 0);
  border: none;
  outline: none;
  color:#FFFFFF;
  min-width: 100%;
  min-height: 43px;
  text-align: center;
  line-height: 150%;
}

.decsVipList {
  max-width: 400px;
}

.inputEmailVipList {
  border: 1px solid #FFFFFF;
  max-width: 400px;
  box-sizing: border-box;
  border-radius: 66px;
}

.buttonVipListDisable {
  text-align: center;
  vertical-align: middle;
  background: linear-gradient(90deg, #958DFF 0%, #8CC6FF 50.34%, #8AE6B6 100%);
  padding: 10px 30px 10px 30px;
  color: rgba(255, 255, 255, 0.25);
  font-weight: 900;
  height: 50px;
  font-size: 16px;
  cursor: pointer;
  border-radius: 40px;
}

.buttonVipList {
  background: #142236;
}

.textButtonVipList {
  background: linear-gradient(90deg, #958DFF 0%, #8CC6FF 50.34%, #8AE6B6 100%);
  -webkit-background-clip: text;
  -webkit-text-fill-color: transparent;
}

.buttonVipListSuccess {
  background: #77D9A6;
  color: #142236;
}

.errorVipList {
  color: #E6456D;
}
</style>